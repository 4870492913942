<template>
  <v-container>
    <v-window v-if="!loading && !success">
      <v-window-item v-if="!modus" >
        <v-row class="text-center" justify="center" style="min-height: 70vh;">
          <v-col cols="12" md="10" lg="9" align-self="center" class="text-center">
            <v-card color="secondary" class="rounded-xl pa-4" @click="modus = 'Teilnehmer'">
              <v-row>
                <v-col cols="auto" align-self="center">
                  <font-awesome-icon size="3x" icon="fa-solid fa-child-reaching" />
                </v-col>
                <v-col align-self="center">
                  <h2>Ich möchte einen Account erstellen, um mich für Sportangebote anzumelden.</h2>
                </v-col>
              </v-row>
            </v-card>
            <h1 class="my-5">ODER</h1>
            <v-card color="secondary" class="rounded-xl pa-4" @click="modus = 'Eltern'">
              <v-row>
                <v-col cols="auto" align-self="center">
                  <font-awesome-icon size="3x" icon="fa-solid fa-family" />
                </v-col>
                <v-col align-self="center">
                  <h2>Ich möchte einen Eltern-Account erstellen, um mein(e) Kind(er) für Sportangebote anzumelden.</h2>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item v-if="modus == 'Eltern'">
        <v-row class="text-center" justify="center">
          <v-col cols="12" md="10" lg="9">
            <v-row justify="center">
              <v-col cols="auto" align-self="center">
                <v-btn icon rounded @click="modus = ''">
                  <font-awesome-icon size="2x" icon="fa-regular fa-arrow-left" />
                </v-btn>
              </v-col>
              <v-col>
                <h1 class="text-h3 text-left font-weight-light">
                  Erstellung eines Eltern-Accounts
                </h1>
              </v-col>
              <v-col cols="12" class="py-4">
                <v-stepper v-model="register_eltern" rounded="xl" color="secondary">
                  <v-stepper-header class="elevation-0">
                    <v-stepper-step :complete="register_eltern > 1" step="1">
                      Infos
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="register_eltern > 2" step="2">
                      Kind(er)
                    </v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step :complete="register_eltern > 3" step="3">
                      Abschluss
                    </v-stepper-step>
                  </v-stepper-header>
                  <v-stepper-items>
                    <v-stepper-content step="1">
                      <v-form
                        ref="step1"
                        v-model="step1"
                      >
                        <v-row class="my-4">
                          <v-col cols="12" md="6">
                            <v-text-field filled rounded hide-details="auto" label="Vorname" :rules="[rules.required, rules.name, rules.gross]" v-model="eltern.vorname" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field filled rounded hide-details="auto" label="Nachname" :rules="[rules.required, rules.name, rules.gross]" v-model="eltern.nachname" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field filled rounded hide-details="auto" label="E-Mail" :rules="[rules.required, rules.email]" v-model="eltern.email" />
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field filled rounded hide-details="auto" label="E-Mail bestätigen" :rules="[rules.required, rules.email, custom_rules.equals]" v-model="eltern.emailconfirm" />
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-stepper-content>
                    <v-stepper-content :complete="register_eltern > 2" step="2">
                      <v-form
                          ref="step2"
                          v-model="step2"
                        >
                      <v-row v-for="(kind, index) in eltern.kinder" :key="index">
                        <v-col cols="12">
                          <v-row justify="center">
                            <v-col cols="auto" align-self="center">
                              <h3>Kind {{index+1}}</h3>
                            </v-col>
                            <v-col cols="auto" align-self="center" class="px-0" v-if="index > 0">
                              <v-btn icon color="red" @click="delete_child(index)"><font-awesome-icon icon="fa-regular fa-user-slash" /></v-btn>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col cols="12">
                          <v-select
                            filled
                            rounded
                            :rules="[rules.required]"
                            :items="['Keine Angabe', 'Männlich', 'Weiblich']"
                            light
                            hide-details="auto" 
                            label="Geschlecht"
                            v-model="kind.geschlecht"
                          ></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field filled rounded hide-details="auto" label="Vorname" :rules="[rules.required, rules.name, rules.gross]" v-model="kind.vorname" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field filled rounded hide-details="auto" label="Nachname" :rules="[rules.required, rules.name, rules.gross]" v-model="kind.nachname" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field filled rounded hide-details="auto" label="Geburtsdatum" v-model="kind.geburtsdatum" :rules="[rules.required, rules.geburtsdatum, custom_rules.alter]" />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select v-model="kind.sportarten" :rules="[rules.required, custom_rules.sportarten]" hide-details="auto"  multiple :items="sportarten" item-value="name" item-text="name" label="Sportarten" rounded filled></v-select>
                        </v-col>
                      </v-row>
                      <v-row justify="center" class="my-2">
                        <v-col cols="auto" class="pa-2">
                          <v-btn rounded large @click="eltern.kinder.push({geschlecht: '', vorname: '', nachname: '', geburtsdatum: '', sportarten: []})"><font-awesome-icon icon="fa-regular fa-person-circle-plus" size="lg" class="mr-2" />Kind hinzufügen</v-btn>
                        </v-col>
                      </v-row>
                      </v-form>
                    </v-stepper-content>
                    <v-stepper-content :complete="register_eltern > 3" step="3">
                      <v-form
                          ref="step3"
                          v-model="step3"
                        >
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-text-field type="password" :rules="[rules.required, rules.password]" v-model="eltern.password" filled rounded label="Passwort"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field type="password" :rules="[rules.required, custom_rules.equals_password]" v-model="eltern.passwordconfirm" filled rounded label="Passwort bestätigen"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-stepper-content>
                  </v-stepper-items>
                  <v-row justify="space-between" class="pa-5">
                    <v-btn text rounded :disabled="register_eltern == 1" @click="register_eltern--">Zurück</v-btn>
                    <v-btn v-if="register_eltern < 3" rounded color="primary" :disabled="!step_valid" @click="register_eltern++">Weiter</v-btn>
                    <v-btn v-if="register_eltern == 3" rounded color="primary" :disabled="!step3" :loading="loading" @click="eltern_registrierung()">Registrieren</v-btn>
                  </v-row>
                </v-stepper>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-window-item>
      <v-window-item v-if="modus == 'Teilnehmer'">
        <v-row class="text-center" justify="center">
          <v-col cols="12" md="10" lg="9">
            <v-row justify="center">
              <v-col cols="auto" align-self="center">
                <v-btn icon rounded @click="modus = ''">
                  <font-awesome-icon size="2x" icon="fa-regular fa-arrow-left" />
                </v-btn>
              </v-col>
              <v-col align-self="center">
                <h1 class="text-h3 text-left font-weight-light">
                  Registrierung
                </h1>
              </v-col>
              <v-col cols="12">
                <v-stepper v-model="register" vertical rounded="xl">
                  <v-stepper-step :complete="register > 1" step="1">
                    <h2 :class="register > 1 ? 'primary--text' : ''">
                      Wie heißt Du?
                      <span
                        class="font-weight-light secondary--text"
                        v-if="register > 1"
                      >
                        {{ person.vorname }} {{ person.nachname }}
                      </span>
                    </h2>
                  </v-stepper-step>

                  <v-stepper-content step="1">
                    <v-row>
                      <v-col cols="12">
                        <v-select
                          filled
                          rounded
                          :items="['Keine Angabe', 'Männlich', 'Weiblich']"
                          light
                          hide-details
                          label="Geschlecht"
                          v-model="person.geschlecht"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="Vorname"
                          v-model="person.vorname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="Nachname"
                          v-model="person.nachname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="text-left mb-3">
                        <v-btn rounded color="primary" :disabled="!person.geschlecht || !person.vorname || !person.nachname" @click="register = 2">
                          Weiter
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-step :complete="register > 2" step="2">
                    <h2 :class="register > 2 ? 'primary--text' : ''">
                      Wie alt bist Du?
                    </h2>
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-menu
                          ref="menu"
                          v-model="menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              :value="datum(person.geburtsdatum)"
                              label="Geburtsdatum"
                              prepend-inner-icon="mdi-calendar"
                              filled
                              hide-details
                              rounded
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="person.geburtsdatum"
                            locale="de"
                            :active-picker.sync="activePicker"
                            :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                            min="2002-01-01"
                            @change="save"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="10" class="text-left mb-3">
                        <v-btn rounded color="primary" :disabled="!person.geburtsdatum" @click="register = 3">
                          Weiter
                        </v-btn>
                        <v-btn text rounded color="primary" @click="register = 1">
                          Zurück
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-step :complete="register > 3" step="3">
                    <h2 :class="register > 3 ? 'primary--text' : ''">
                      Welcher Sport interessiert dich?
                    </h2>
                  </v-stepper-step>
                  <v-stepper-content step="3">
                    <v-row>
                      <v-col cols="12" class="text-left">
                        <h4>
                          <span v-if="minsportarten > 0">
                            Bitte wähle noch mindestens
                            {{ minsportarten }} Sportarten aus:
                          </span>
                          &nbsp;
                        </h4>
                      </v-col>
                      <v-col
                        cols="6"
                        md="3"
                        v-for="(sport, index) in sportarten"
                        :key="index"
                      >
                        <v-card
                          link
                          outlined
                          :color="
                            person.sportarten.includes(sport.name)
                              ? 'primary'
                              : undefined
                          "
                          class="rounded-xl h-100 pa-2"
                          :style="
                            person.sportarten.includes(sport.name)
                              ? 'color: #FFFFFF;'
                              : undefined
                          "
                          @click="select(sport.name)"
                        >
                          <v-row justify="center" class="h-100">
                            <v-col
                              cols="12"
                              class="text-center"
                              align-self="center"
                            >
                              <font-awesome-icon :color="
                                  person.sportarten.includes(sport.name)
                                    ? 'white'
                                    : undefined
                                " size="2x" v-if="sport.iconset == 'Fontawesome'" :icon="sport.icon" class="mr-1" />
                              <v-icon x-large v-if="sport.iconset == 'Material' || !sport.iconset" :color="
                                  person.sportarten.includes(sport.name)
                                    ? 'white'
                                    : undefined
                                ">{{ sport.icon }}</v-icon>
                              <h3>
                                {{ sport.name }}
                              </h3>
                            </v-col>
                          </v-row>
                        </v-card>
                      </v-col>
                      <v-col cols="12" class="text-left mb-3">
                        <v-btn rounded color="primary" :disabled="minsportarten > 0" @click="register = 4">
                          Weiter
                        </v-btn>
                        <v-btn text rounded color="primary" @click="register = 2">
                          Zurück
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>

                  <v-stepper-step :complete="register > 4" step="4">
                    <h2 :class="register > 4 ? 'primary--text' : ''">
                      Daten eines Erziehungsberechtigten
                    </h2>
                  </v-stepper-step>
                  <v-stepper-content step="4">
                    <v-row>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="Vorname"
                          v-model="person.erziehungsberechtigte[0].vorname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="Nachname"
                          v-model="person.erziehungsberechtigte[0].nachname"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="E-Mail"
                          v-model="person.erziehungsberechtigte[0].email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="Telefon"
                          v-model="person.erziehungsberechtigte[0].telefon"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="text-left mb-3">
                        <v-btn rounded color="primary" :disabled="!person.erziehungsberechtigte[0].vorname || !person.erziehungsberechtigte[0].nachname || !person.erziehungsberechtigte[0].email || !person.erziehungsberechtigte[0].telefon" @click="register = 5
                        person.email = person.erziehungsberechtigte[0].email">
                          Weiter
                        </v-btn>
                        <v-btn text rounded color="primary" @click="register = 3">
                          Zurück
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                  <v-stepper-step :complete="register > 5" step="5">
                    <h2 :class="register > 4 ? 'primary--text' : ''">
                      Login Daten
                    </h2>
                  </v-stepper-step>
                  <v-stepper-content step="5">
                    <v-row>
                      <v-col cols="12" md="8">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          label="E-Mail"
                          v-model="person.email"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          :type="show ? 'text' : 'password'"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          label="Passwort"
                          @click:append="show = !show"
                          v-model="person.password"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="5">
                        <v-text-field
                          filled
                          rounded
                          light
                          hide-details
                          :type="show2 ? 'text' : 'password'"
                          :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                          label="Passwort bestätigen"
                          @click:append="show2 = !show2"
                          v-model="person.passwordconfirm"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="text-left mb-3">
                        <v-btn rounded color="primary" :disabled="!person.email || !person.password || person.password != person.passwordconfirm" @click="registrierung()">
                          Registrieren
                        </v-btn>
                        <v-btn text rounded color="primary" @click="register = 4">
                          Zurück
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-stepper-content>
                </v-stepper>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-window-item>
    </v-window>
    <v-window v-if="loading">
      <v-row style="min-height: 50vh;">
        <v-col cols="12" class="text-center" align-self="center">
          <h1 class="primary--text">Registrierung läuft</h1>
        </v-col>
        <v-col cols="12" class="text-center" align-self="center">
          <v-progress-circular color="primary" indeterminate size="90">
          </v-progress-circular>
        </v-col>
      </v-row>
    </v-window>
    <v-window v-if="!loading && success">
      <v-row justify="center" style="min-height: 70vh;">
        <v-col cols="10" md="8" class="text-center success--text" align-self="center">
          <font-awesome-icon icon="fa-duotone fa-circle-check" size="9x" />
          <h2 class="mt-4 font-weight-light">Registrierung war erfolgreich</h2>
          <h1>Willkommen {{modus == 'Eltern' ? eltern.vorname : person.vorname }}!</h1>
          <v-row justify="center" class="mt-4">
            <v-col cols="12" class="my-3">
              <p class="black--text my-3" v-if="modus == 'Eltern'">Wir haben dir eine E-Mail mit einem Aktiverungslink zugeschickt. Bitte klicke auf diesen Link um deinen Account zu aktivieren. Ohne die Aktivierung deines Accounts kannst du deine Kinder nicht für Sportangebote anmelden.</p>
              <p class="black--text my-3" v-else>Wir haben deinem Erziehungsberechtigten eine E-Mail mit einem Aktivierungslink zugeschickt. Bis dein erziehungsberechtigter deinen Account aktiviert hat, kannst du dich noch nicht für die Sportangebote anmelden.</p>
            </v-col>
            <v-col cols="12" class="text-center" align-self="center">
              <v-btn large color="primary" rounded :to="modus == 'Teilnehmer' ? '/user' : '/eltern'">
                JETZT LOSLEGEN
              </v-btn>
              <v-btn large color="primary" class="ma-4" outlined rounded :to="modus == 'Teilnehmer' ? '/user/aktivierung' : '/eltern/aktivierung'">
                ACCOUNT AKTIVIEREN
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-window>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  getFirestore,
  collection,
  query,
  where,
  doc,
  onSnapshot,
  updateDoc,
  addDoc
} from 'firebase/firestore'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import router from '../router';

const auth = getAuth();

export default {
  name: 'Registrierung',
  data() {
    return {
      custom_rules: {
        equals: (v) => v && this.eltern.email && v == this.eltern.email ? true : 'Die E-Mail Adressen stimmen nicht überein',
        equals_password: (v) => v && this.eltern.password && v == this.eltern.password ? true : 'Die Passwörter stimmen nicht überein',
        alter: (v) => v && v.split('.').length == 3 && v.split('.')[2] >= 2004 ? true : 'Dein Kind scheint zu alt für die Sportangebote zu sein.',
        sportarten: (v) => v && v.length >= 3 ? true : 'Bitte wähle mindestens 3 Sportarten aus.'
      },
      modus: '',
      activePicker: null,
      step1: false,
      step2: false,
      step3: false,
      activePicker_eltern: [
        {
          picker: null
        }
      ],
      geburtsdatum: null,
      menu: false,
      menu_eltern: [false],
      show: false,
      show2: false,
      sports: [
        {
          icon: 'yoga',
          title: 'Turnen',
        },
        {
          icon: 'soccer',
          title: 'Fußball',
        },
        {
          icon: 'handball',
          title: 'Handball',
        },
        {
          icon: 'basketball',
          title: 'Basketball',
        },
        {
          icon: 'bike',
          title: 'Radfahren',
        },
        {
          icon: 'bullseye-arrow',
          title: 'Darts',
        },
        {
          icon: 'golf',
          title: 'Golf',
        },
        {
          icon: 'table-tennis',
          title: 'Tischtennis',
        },
        {
          icon: 'karate',
          title: 'Karate',
        },
        {
          icon: 'run-fast',
          title: 'Leichtathletik',
        },
        {
          icon: 'swim',
          title: 'Schwimmen',
        },
        {
          icon: 'tennis-ball',
          title: 'Tennis',
        },
        {
          icon: 'volleyball',
          title: 'Volleyball',
        },
        {
          icon: 'bowling',
          title: 'Sportkegeln',
        },
        {
          icon: 'badminton',
          title: 'Badminton',
        },
      ],
      register: 1,
      register_eltern: 1,
      other_email: {
        value: true,
        email: ''
      },
      person: {
        vorname: '',
        nachname: '',
        geschlecht: '',
        geburtsdatum: '',
        sportarten: [],
        email: '',
        password: '',
        passwordconfirm: '',
        erziehungsberechtigte: [
          {
            vorname: '',
            nachname: '',
            email: ''
          }
        ]
      },
      eltern: {
        vorname: '',
        nachname: '',
        email: '',
        password: '',
        passwordconfirm: '',
        kinder: [
          {
            vorname: '',
            nachname: '',
            geschlecht: '',
            geburtsdatum: '',
            sportarten: []
          }
        ]
      },
      minvalue: 3,
      loading: false,
      success: false
    }
  },
  watch: {
      menu (val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
    },
  computed: {
    ...mapGetters({
      user: 'user',
      rules: 'rules',
      vereine: 'vereine',
      db: 'db',
      sportarten: 'sportarten',
      sportangebote: 'sportangebote',
    }),
    sportarten() {
      var arr = this.sports
      arr.sort((a, b) => {
        if (a.name > b.name) {
          return 1
        } else {
          return -1
        }
      })

      return arr
    },
    minsportarten() {
      return this.minvalue - this.person.sportarten.length
    },
    step_valid() {
      if(this.register_eltern == 1){
        if(this.step1 && this.eltern.vorname && this.eltern.nachname && this.eltern.email && this.eltern.email == this.eltern.emailconfirm){
          return true
        }
      }
      if(this.register_eltern == 2){
        if(this.step2 && this.eltern.kinder.length > 0 && this.eltern.kinder.filter(v => !v.geschlecht || !v.vorname || !v.nachname || !v.geburtsdatum || !v.sportarten).length == 0){
          return true
        }
      }
      return false
    }
  },
  mounted(){
    if(this.user.loggedIn){
      router.push(this.user.data.eltern ? '/eltern' : '/user')
    }
    else {
      onSnapshot(query(collection(getFirestore(), 'Sportarten'), where('name', '!=', '#')),
        (items) => {
          this.sports = []
          items.forEach((item) => {
            var daten = item.data()
            daten.id = item.id
            this.sports.push(daten)
          })
        })
    }
        
  },
  methods: {
    async registrierung(){
      createUserWithEmailAndPassword(auth, this.person.email, this.person.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        this.loading = true

        const unsub = onSnapshot(doc(this.db, "User", user.uid), async (snap) => {
            if(snap.exists()){
              if(!snap.data().vorname && snap.data().authCode){
                const userdataRef = doc(this.db, "User", user.uid);

                await updateDoc(userdataRef, {
                  geschlecht: this.person.geschlecht,
                  vorname: this.person.vorname,
                  nachname: this.person.nachname,
                  geburtsdatum: this.person.geburtsdatum,
                  sportarten: this.person.sportarten,
                  erziehungsberechtigte: this.person.erziehungsberechtigte
                });

                await addDoc(collection(this.db, "email"), {
                  vorname: this.person.erziehungsberechtigte[0].vorname,
                  uservorname: this.person.vorname,
                  usernachname: this.person.nachname,
                  authCode: snap.data().authCode,
                  uid: user.uid,
                  to: this.person.erziehungsberechtigte[0].email,
                  template: 'REGISTRIERUNG'
                });
                this.loading = false
                this.success = true
              }

            }
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log(error)
      });
    },
    async eltern_registrierung(){
      createUserWithEmailAndPassword(auth, this.eltern.email, this.eltern.password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        this.loading = true

        const unsub = onSnapshot(doc(this.db, "User", user.uid), async (snap) => {
            if(snap.exists()){
              if(!snap.data().vorname && snap.data().authCode){
                const userdataRef = doc(this.db, "User", user.uid);

                await updateDoc(userdataRef, {
                  eltern: true,
                  vorname: this.eltern.vorname,
                  nachname: this.eltern.nachname,
                  kinder: this.eltern.kinder
                });

                await addDoc(collection(this.db, "email"), {
                  vorname: this.eltern.vorname,
                  kinder: this.eltern.kinder,
                  authCode: snap.data().authCode,
                  uid: user.uid,
                  to: this.eltern.email,
                  template: 'REGISTRIERUNG_ELTERN'
                });
                this.loading = false
                this.success = true
              }

            }
        });
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        // ..
        console.log(error)
      });
    },
    save (date) {
        this.$refs.menu.save(date)
      },
    datum(date){
      if(date){
        var datum = date.split('-')
        return datum[2]+'.'+datum[1]+'.'+datum[0]
      }
      else {
        return date
      }
    },
    select(title) {
      if (this.person.sportarten.includes(title)) {
        var old = this.person.sportarten
        this.person.sportarten = []
        old.forEach((s) => {
          if (s != title) {
            this.person.sportarten.push(s)
          }
        })
      } else {
        this.person.sportarten.push(title)
      }
    },
    delete_child(index){
      const childs_old = this.eltern.kinder
      this.eltern.kinder = childs_old.filter((v, i) => i != index)
    }
  },
}
</script>
