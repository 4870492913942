<template>
  <v-slide-x-transition>
    <registrierung />
  </v-slide-x-transition>
</template>

<script>
import Registrierung from '../components/Registrierung'

export default {
  name: 'Register',

  components: {
    Registrierung,
  },
}
</script>
